<template>
  <section class="min-w1600">
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
       </div>
       <div class="searchBar">
         <div :class="'searchbar-content'">
            <div class="title"><span>{{ $t('table.head.category') }}</span></div>
            <div>
               <select v-model="searchType" class="mr-5 w90">
                 <option value="topMemId">{{ $t('common.id') }}</option>
                 <option value="topMemNick">{{ $t('common.nickName') }}</option>
               </select>
               <input type="text" class="mr-5" v-model="searchText" />
            </div>
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.searchoption') }}</span></div>
           <div>
             <select v-model="reqData.cashType" class="mr-5 w124">
               <option value="">{{ $t('common.allH') }}</option>
               <option value="-3">{{ $t('searchArea.payment') }}</option>
               <option value="3">{{ $t('searchArea.retrieval') }}</option>
             </select>
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
         <!--div :class="'searchbar-content'">
           <div class="title"><span>상위회원</span> :</div>
           <input type="text" class="mr-5" v-model="reqData.topMemId" />
         </div-->

       </div>
       <memo></memo>
    </div>

    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
            <tr>
              <th>{{ $t(`table.head.idx`) }}</th>
              <th>{{ $t(`table.head.memId`) }}</th>
              <th>{{ $t(`table.head.memNick`) }}</th>
              <th>{{ $t(`table.head.level`) }}</th>
              <th>{{ $t(`table.head.upper`) }}</th>
              <th>{{ $t(`table.body.move`) }}</th>
              <th>{{ $t(`table.head.memId`) }}</th>
              <th>{{ $t(`table.head.memNick`) }}</th>
              <th>{{ $t(`table.head.level`) }}</th>
              <th>{{ $t(`table.head.upper`) }}</th>
              <th>{{ $t(`table.head.content`) }}</th>
              <th>{{ $t(`table.head.befMy`) }}</th>
              <th>{{ $t(`table.head.moveMy`) }}</th>
              <th>{{ $t(`table.head.aftMy`) }}</th>
              <th>{{ $t(`table.head.compltTime`) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="cashList.length !== 0">
              <tr v-for="(item, idx) in cashList" :key="item.cashIdx">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.topMemId)">{{ item.topMemId }}</button>
                </td>
                <td>
                  <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.topMemId)">{{ item.topMemNick }}</button>
                </td>
                <td :class="item.topPartnerLevel">
                  <span class="box">{{item.topPartnerLevelName}}</span>
               </td>
               <td>
                  <div class="topwrap" v-if="item.topMemTopUserList && item.topMemTopUserList.length > 0" >
                     <select class="select">
                       <template v-for="pt in item.topMemTopUserList" :key="pt.recommenderId">
                         <option class="option">
                            <span class="topbox">[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                         </option>
                       </template>
                     </select>
                     <i class="icon">+</i>
                  </div>
                 <div v-else>
                   -
                 </div>
               </td>
                <td>
                   <span :class="item.cashType > 0 ? 'icon-rd' : 'icon-bl'"></span>
                </td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.botMemId)">{{ item.botMemId }}</button>
                </td>
                <td>
                  <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.botMemId)">{{ item.botMemNick }}</button>
                </td>
                <td :class="item.botPartnerLevel">
                  <span class="box">{{item.botPartnerLevelName}}</span>
               </td>
               <td>
                  <div class="topwrap" v-if="item.botMemTopUserList">
                     <select class="select">
                       <template v-for="pt in item.botMemTopUserList" :key="pt.recommenderId">
                         <option class="option">
                            <span class="topbox">[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                         </option>
                       </template>
                     </select>
                     <i class="icon">+</i>
                  </div>
               </td>
                <td>{{ item.cashDesc }}</td>
                <td class="roboto">{{ numberWithCommas(item.preCashAmt) }}</td>
                <td class="roboto" :class="item.cashAmt > 0 ? 'fc-red' : 'fc-blue'">{{ numberWithCommas(item.cashAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(item.aftCashAmt) }}</td>
                <td class="fc-red roboto">{{ replaceDateT(item.regDate) }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="16">
                  {{ $t('txt.noData') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="cashList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { cashSendList, memberCashStop } from '@/api/member.js'
import { replaceDateT, numberWithCommas } from '@/libs/utils.js'
import Memo from '@/components/common/memo'

export default {
  name: 'UserCashChangeHistory',
  components: {
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'cashAmt', 'totalCashInAmt', 'totalCashOutAmt', 'progBetAmt', 'totalWlAmt', 'option']
        // ,
        // addAttrs:[
        //   {
        //     target:"totalCashInAmt",
        //     attrs:{
        //       "col-span":2
        //     }
        //   },
        //   {
        //     target:"totalCashOutAmt",
        //     attrs:{
        //       "col-span":2
        //     }
        //   }
        // ]
      },
      reqData: {
        cashType: '',
        topMemId: '',
        botMemId: '',
        topMemNick: '',
        startDate: '',
        endDate: '',
        page: 1,
        count_per_list: 30,
        count_per_page: 10,
        oldYn: 'N'
      },
      srchFiltersProp: {
        placeholder: '아이디 검색',
        selectOptions: ''
      },
      searchType: 'topMemId',
      searchText: '',
      cashList: [],
      pageInfo: {
        count_per_list: 30,
        count_per_page: 20,
        function_name: 'goPage',
        limit: 30,
        offset: 0,
        page: 1,
        tatal_list_count: 2,
        tatal_page_count: 0
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      dateProps: {
        start: true,
        end: true
      },
      startDefault: '',
      endDefault: ''
    }
  },
  methods: {
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.setTableData(1)
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.topMemId = ''
      this.reqData.topMemNick = ''
      this.reqData[this.searchType] = this.searchText
      console.log('req : ,', this.reqData)
      const data = this.reqData
      const listRes = await cashSendList(data)
      if (listRes.resultCode === '0') {
        const cashList = listRes.data.list
        console.log('res : ', cashList)
        this.cashList = cashList
        const pageInfo = listRes.data.pageInfo
        this.pageInfo = Object.assign(this.pageInfo, pageInfo)
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.topMemId = this.$route.query.id
      this.searchText = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.active .btnPhoneNum {
  display: none;
}
.mainTable {
  /*table-layout: fixed;*/
}
.searchBar > .title {
  gap: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
}
.icon-rd {background: url(~@/assets/img/icon-move-rd.svg) center no-repeat;display: flex;width: 26px;height: 20px;margin: 0 auto;}
.icon-bl {background: url(~@/assets/img/icon-move-bl.svg) center no-repeat;display: flex;width: 26px;height: 20px;margin: 0 auto;}
</style>
